<template>
	<v-container>
		<h1 class="mb-6">Замовлення</h1>

		<v-card
			v-for="order in orders"
			:key="order.id"
			:to="{
				name: 'order',
				params: {
					orderid: order.id,
				},
			}"
			elevation="24"
			class="mb-6">

			<v-card-title class="justify-space-between">
				<div>
					{{ order.created_at | date }}
				</div>
					
				<div class="text-h6 font-weight-black font-italic success--text text-no-wrap">
					{{ order.total_amount }}₴
				</div>
			</v-card-title>

			<v-card-text>
				<OrderStatusChip
					:status="order.status">
				</OrderStatusChip>
			</v-card-text>
			
			<v-card-text>
				Номер замовлення:
				<v-chip small>
					{{ order.id }}
				</v-chip>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import Order from '../models/Order'

import OrderStatusChip from '../components/Order/OrderStatusChip.vue'

export default {
	components: {
		OrderStatusChip,
	},
	computed: {
		orders() {
			return Order.all()
		},
	},
	methods: {
		async loadOrders() {
			try {
				await Order.fetchGetAll()
			} catch (error) {
				this.$swal('error', {
					title: 'Не вдалося завантажити список твоїх замовлень',
				})
				throw error
			}
		},
	},
	mounted() {
		this.loadOrders()
	},
}
</script>
