<template>
	<v-chip :color="color">
		{{ status }}
	</v-chip>
</template>

<script>
export default {
	props: {
		status: {
			type: String,
			required: true,
		},
	},
	computed: {
		color() {
			if (this.status === 'completed') {
				return 'success'
			} else if (this.status === 'pending') {
				return null
			} else {
				return 'error'
			}
		},
	},
}
</script>